import {
  AUCTION_STATUS_IN_PROGRESS,
  CARESEEKER_TYPE_HOSPITAL,
  CARE_SOLUTIONS,
  GENDER_MALE,
  HOME_CARE_SOLUTIONS,
  HOME_CARE_SPECIALIZATION_TRACHEOSTOMY_THERAPY,
  HOSPITAL_SOLUTIONS,
  LIVING_SITUATION_WITH_FAMILY,
  MEDICAL_SUPPLIES_SOLUTIONS,
  PREDICAMENT_STATE_NO,
  PREDICAMENT_STATE_UNKNOWN,
  PREDICAMENT_STATE_YES,
  REHABILITATION_SOLUTIONS,
  RELIEF_SERVICES_PARAGRAPH_B,
  SEARCH_ACTION_EDIT_SEARCH,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
  SERVICE_BASIC_CARE,
  SERVICE_BASIC_MEDICAL_CARE,
  SERVICE_CLOSED_AREA,
  SERVICE_COMPANIONSHIP,
  SERVICE_PALLIATIVE_CARE,
  SERVICE_PALLIATIVE_CERTIFICATE,
  SERVICE_PSYCHIATRIC_CARE,
  SERVICE_SPECIALISED_INTENSIVE_NURSE,
  SERVICE_TRACHEOSTOMY,
  SHORT_TERM_CARE_GRANT_42,
  SOLUTION_FULL_HOSPITALIZATION,
  SOLUTION_FULL_TIME_MOBILE_CARE,
  SOLUTION_MOBILE_CARE,
  SPECIALIZATION_ACUTE_GERIATRICS,
  SPECIALIZATION_ENT,
  SPECIALIZATION_ORTHOPEDICS,
  SPECIALIZATION_PLASTIC_SURGERY,
  TRANSPORT_SOLUTIONS,
  VIGILANCE_CALM,
} from "core/consts";
import { mockAuction } from "core/mocks";
import {
  AssessmentVariantType,
  Auction,
  Careseeker,
  Specialization,
} from "core/types";
import { getUnixTime } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { getTestTranslations } from "translations/test";
import "whatwg-fetch";
import { AssessmentStepProps } from "./AssessmentStep";

const encryptedField = (decrypted: string) => ({
  content: "123",
  seald_content: "123",
  iv: "123iv",
  decrypted,
});

export const auction: Auction = mockAuction({
  profile: {
    search_location: {
      latitude: 50.65645,
      longitude: 7.8319,
      radius_in_meter: 100,
      encrypted_house_number: encryptedField("125"),
      zipcode: "10999",
      street: "Reichenberger Strasse",
      city: "Berlin",
    },
  },
  start_date: getUnixTime(zonedTimeToUtc(new Date(2018, 10, 1), "UTC")),
  search_type: SEARCH_TYPE_CARE,
  status: AUCTION_STATUS_IN_PROGRESS,
  solutions: [SOLUTION_MOBILE_CARE, SOLUTION_FULL_TIME_MOBILE_CARE],
  radius_in_meter: 15000,
  services: [
    SERVICE_PALLIATIVE_CARE,
    SERVICE_PALLIATIVE_CERTIFICATE,
    SERVICE_TRACHEOSTOMY,
    SERVICE_SPECIALISED_INTENSIVE_NURSE,
    SERVICE_BASIC_CARE,
    SERVICE_COMPANIONSHIP,
    SERVICE_PSYCHIATRIC_CARE,
    SERVICE_BASIC_MEDICAL_CARE,
    SERVICE_CLOSED_AREA,
  ],
  patient: {
    id: 44,
    user_id: "J2P-NQ9-PNO",
    external_id: "cid123",
    drg_code: "A45D",
    version: "2",
    careseeker: {
      __typename: "Careseeker",
      id: 1,
      type: CARESEEKER_TYPE_HOSPITAL,
      name: "Vivantes Hospital",
      address: {
        address: "Kottbuser Tor 1",
        zipcode: "10-001",
        city: "Berlin",
        country: "DE",
      },
    },
    diagnosis: {
      icd_code: {
        code: "Z01.81",
        description: "Abklärung einer Disposition für Kardiomyopathien",
      },
      additional_icd_codes: [
        {
          code: "Z99.1",
          description: "Abhängigkeit (langzeitig) vom Respirator",
        },
      ],
      corona: {
        test_result: 1,
        description: "PCR-Test done prior to the admission",
        vaccinated:
          "Patient has received first injection of BioNTech/Pfizer vaccine",
      },
      corona_state: 2,
      main_diagnosis:
        "Patient has light dementia and needs mobile care twice a day",
      secondary_diagnosis: "Brain tumor",
      multimorbidity: true,
      infection_and_germs_state: PREDICAMENT_STATE_YES,
      infection_and_germs: {
        clostridien: true,
        description: "",
        four_mrgn: true,
        mrsa: true,
        other: "other infection and germs",
        remarks: "remarks",
        requires_isolation: false,
        three_mrgn: true,
        vre: true,
      },
      dependency_diagnosis: {
        barthel_assessment: {
          date: 1593993600,
          score: 65,
        },
        dependency_level: 0,
        early_rehab_assessment: undefined,
      },
      mental_health: {
        orientation: 1,
        motivation: 1,
        communication: 1,
        dementia_description: "has dementia",
        run_away_description: "has run away behavior",
        depression: true,
        anxiety: false,
        aggressivity: true,
        mental_issues: "has other mental issues",
        protected_area_needed: "needs protected area",
      },
      rehabilitation_potential: {
        description: "Weitere Erläuterungen",
        evaluation: 1,
      },
      dysphagia: "Dysphagia description",
    },
    profile: {
      __typename: "PatientProfile",
      age_interval: {
        min: 18,
        max: 50,
      },
      birth_date: encryptedField("12/12/1995"),
      first_name: encryptedField("Jean"),
      last_name: encryptedField("Bon"),
      gender: 1,
      care_duration_in_days: 7,
      interested_long_term_stay: false,
      living_situation: LIVING_SITUATION_WITH_FAMILY,
      living_situation_other: undefined,
      lift_available_state: PREDICAMENT_STATE_NO,
      lift_available_description:
        "Patient is living on 3rd floor without any lift",
      barrier_free: PREDICAMENT_STATE_NO,
      barrier_free_description: "Access to bathtub not possible",
      unsafe_current_domestic_situation_state: PREDICAMENT_STATE_NO,
      unsafe_current_domestic_situation: "The floor is lava",
      communication: {
        has_guardian: false,
        guardian_description: "John Smith, Certified Guardian",
        guardian_requested: true,
        patient_cannot_communicate_in_german: true,
        patient_language: "japanese",
        patient_is_contact: true,
      },
      preferences: {
        accompanying_person: true,
        barrier_free_room: true,
        room_type: 4,
        specialist_doctor: true,
      },
      financing: {
        reimbursment_secured: 1,
        reimbursment_for_help_at_home: 1,
        preventative_care: 2,
        private_payment_bool: true,
        social_help_recipient_bool: true,
        more_information: "Grants more information",
        short_term_care: SHORT_TERM_CARE_GRANT_42,
        relief_services: RELIEF_SERVICES_PARAGRAPH_B,
        insurance_id: 75,
        insurance_number: encryptedField("ABCXYZ"),
        insurance: {
          id: 75,
          name: "Top Gun Insurance",
        },
        payout: undefined,
        carelevel: {
          level: 4,
          higher_level: true,
          has_applied: true,
          expedited_request: true,
        },
      },
    },
    care_needs: {
      base_care: null,
      nutrition: null,
      dialysis: null,
      psychiatric_care: {
        description: "Trumpy",
      },
      palliative: {
        description: "He is a zombie and will infect people",
        palliative_certificate_needed: true,
      },
      wound_care: {
        description: "Twice a day",
      },
      compression: {
        description: "Daily",
      },
      medication: {
        description: "Weekly",
      },
      breathing: {
        tracheostomy: "Requires a breathing machine",
        specialised_intensive_nurse: true,
      },
      injection: {
        description: "At monthly intervals",
      },
      access_drainages: {
        tracheal_cannula: "tracheal_cannula description",
        gastric_tube: "gastric_tube description",
        central_venous_catheter: "central_venous_catheter description",
        peripheral_venous_catheter: "peripheral_venous_catheter description",
        suprapubic_catheter: "suprapubic_catheter description",
        permanent_catheter: "permanent_catheter description",
        drainage: "drainage description",
        other_access_drainages: "other_access_drainages description",
      },
      incontinence: {
        urinary_incontinence: "",
      },
      companionship: {
        description: "companionship description",
        companionship_grant: true,
      },
    },
    transport: {
      require_accompanying_person: true,
      accompanying_person: encryptedField("James Bond"),
      daily_transport_needed: true,
      transport_organiser: "my own",
      transport_type: [1, 2, 3, 4, 5],
    },
    station_full: {
      name: "Station A",
      description: "Intensive Care",
    },
    social_worker: {
      __typename: "Account",
      id: 1,
      first_name: "Bob",
      last_name: "Harris",
      title: GENDER_MALE,
      phone: "666-666-666",
      email: "bobharris@clinic.com",
    },
    assignee: {
      __typename: "Account",
      id: 1,
      first_name: "Bob",
      last_name: "Harris",
      title: GENDER_MALE,
      phone: "666-666-666",
      email: "bobharris@clinic.com",
    },
  },
});

export const careAuction: Auction = {
  ...auction,
  id: 1,
  search_type: SEARCH_TYPE_CARE,
  solutions: auction.solutions,
  patient: {
    ...auction.patient,
    id: 44,
  },
};

export const transportAuctionFiller: Auction = {
  __typename: "Auction",
  id: 1,
  search_type: SEARCH_TYPE_TRANSPORT,
  solutions: TRANSPORT_SOLUTIONS,
  patient: {
    id: 1,
    care_needs: {
      remarks: "needs remarks",
    },
  },
  profile: {
    no_site_support_required: true,

    pick_up: {
      end: {
        hour: 13,
        minute: 50,
      },
      start: {
        hour: 12,
        minute: 50,
      },
    },
    search_destination: {
      city: "Berlin",
      country: "",
      encrypted_house_number: encryptedField("125"),
      floor: "3",
      latitude: 52.501602955488856,
      longitude: 13.415921286955657,
      radius_in_meter: 500,
      street: "Bergfriedstraße",
      zipcode: "10969",
    },
    search_location: {
      city: "Berlin",
      country: "",
      encrypted_house_number: encryptedField("125"),
      floor: "1",
      latitude: 52.57831087214919,
      longitude: 13.185692382622772,
      radius_in_meter: 500,
      street: "Havelkaiser-Platz",
      zipcode: "13587",
    },
  },
};

export const medicalSuppliesAuctionFiller = {
  id: 12,
  search_type: SEARCH_TYPE_MEDICAL_SUPPLIES,
  solutions: MEDICAL_SUPPLIES_SOLUTIONS,
  medical_supplies: {
    additional_information: "ADD INFO",
    delivery_address: {
      __typename: "Location",
      city: "Berlin",
      country: "",
      encrypted_city: null,
      encrypted_house_number: encryptedField("12"),
      encrypted_street_and_house_number: null,
      floor: "2",
      latitude: 52.549010312936055,
      longitude: 13.417658620147128,
      radius_in_meter: 500,
      street: "Rodenbergstraße",
      zipcode: "10439",
    },
    delivery_address_type: 3,
    prescription_issued: true,
    prescription_issued_description: "PRESCRIPTION",
    products: [
      {
        description:
          "Dienstleistungsbestandteile (DLB) mechatronischer Ellenbogen",
        id: "38.90.06.0",
        location: 90,
        name: "Digitale Steuerung",
        product_group: 38,
        subgroup: 6,
        type: 0,
      },
      {
        description:
          "Dienstleistungsbestandteile (DLB) mechatronischer Ellenbogen",
        id: "38.82.99.0",
        location: 82,
        name: "Dienstleistungsbestandteile (DLB) mechatronischer Ellenbogen",
        product_group: 38,
        subgroup: 99,
        type: 0,
      },
      {
        description: "Desinfektionsmittel",
        id: "54.99.02.0",
        location: 99,
        name: "Desinfektionsmittel",
        product_group: 54,
        subgroup: 2,
        type: 0,
      },
      {
        description:
          "Dienstleistungsbestandteile (DLB) multiartikulierende Hand",
        id: "38.82.98.9",
        location: 82,
        name: "Dienstleistungsbestandteile (DLB) multiartikulierende Hand",
        product_group: 38,
        subgroup: 98,
        type: 9,
      },
      {
        description:
          "Dienstleistungsbestandteile (DLB) multiartikulierende Hand",
        id: "38.08.98.9",
        location: 8,
        name: "Dienstleistungsbestandteile (DLB) multiartikulierende Hand",
        product_group: 38,
        subgroup: 98,
        type: 9,
      },
    ],
  },
};

export const transportAuction: Auction = {
  ...auction,
  ...transportAuctionFiller,
  patient: {
    ...auction.patient,
    id: 44,
  },
};

export const hospitalAuction: Auction = {
  ...auction,
  id: 1,
  search_type: SEARCH_TYPE_HOSPITAL,
  search_actions: [{ action_type: SEARCH_ACTION_EDIT_SEARCH }],
  specializations: [SPECIALIZATION_ACUTE_GERIATRICS],
  additional_specializations: [
    SPECIALIZATION_ENT,
    SPECIALIZATION_PLASTIC_SURGERY,
  ],
  solutions: [SOLUTION_FULL_HOSPITALIZATION],
  patient: {
    ...auction.patient,
    id: 46,
    profile: {
      __typename: "PatientProfile",
      ...auction.patient.profile,
      age_interval: {
        min: null,
        max: 60,
      },
      financing: {
        patient_has_health_insurance_state: PREDICAMENT_STATE_YES,
      },
    },
    diagnosis: {
      ...auction.patient.diagnosis,
      medical_history: "Spine surgery 3 years ago",
      additional_examinations_necessary: "MRI",
      dependency_diagnosis: {
        requires_walking_aid: PREDICAMENT_STATE_UNKNOWN,
        requires_support_with_feeding: PREDICAMENT_STATE_YES,
        agitated: PREDICAMENT_STATE_NO,
        agitated_description: "agitated description",
        has_help_at_home: PREDICAMENT_STATE_NO,
        returning_home_short_term: PREDICAMENT_STATE_NO,
      },
    },
    care_needs: {
      ...auction.patient.care_needs,
      medication: {
        blood_thinner: "required",
      },
    },
  },
};

export const rehabAuction: Auction = {
  ...auction,
  id: 1,
  solutions: [...REHABILITATION_SOLUTIONS],
  search_actions: [{ action_type: SEARCH_ACTION_EDIT_SEARCH }],
  specializations: [SPECIALIZATION_ORTHOPEDICS],
  search_type: SEARCH_TYPE_REHABILITATION,
  payers: {
    supplementary_insurance: "supplementary_insurance description",
    supplementary_insurance_state: PREDICAMENT_STATE_YES,
    supplementary_payment: "supplementary_payment description",
    supplementary_payment_state: PREDICAMENT_STATE_YES,
    free_from_supplementary_payment_state: PREDICAMENT_STATE_YES,
  },
  patient: {
    ...auction.patient,
    id: 47,
    diagnosis: {
      ...auction.patient.diagnosis,
      limited_capacity: {
        limited_cognitive_capacity: "confirmed",
        limited_physical_capacity: "",
        limited_psychological_capacity: "",
        limited_social_behaviour: "",
        other: "",
      },
      mental_health: {
        ...auction.patient.diagnosis?.mental_health,
        vigilance: VIGILANCE_CALM,
        closed_station: true,
        fixation: true,
        dangerous: true,
      },
    },
    profile: {
      __typename: "PatientProfile",
      ...auction.patient.profile,
      direct_transfer_necessary: true,
    },
    care_needs: {
      ...auction.patient.care_needs,
      support: {
        eating: true,
        body_care: true,
        dressing: true,
      },
      monitoring: {
        circulation: "circulation, a little something",
        respiration: "respiration, a little something",
        metabolism: "metabolism, a little something",
      },
    },
  },
};

export const homecareAuction: Auction = {
  ...auction,
  id: 1,
  search_actions: [{ action_type: SEARCH_ACTION_EDIT_SEARCH }],
  solutions: [...HOME_CARE_SOLUTIONS],
  specializations: [HOME_CARE_SPECIALIZATION_TRACHEOSTOMY_THERAPY],
  search_type: SEARCH_TYPE_HOME_CARE,
  patient: {
    ...auction.patient,
    id: 47,
  },
};

const mockEncryptedContent = {
  content: "encryptedContent",
  seald_content: "encryptedContent",
  iv: "iv",
};

function addEncryptedFields(auction: Auction): Auction {
  return {
    ...auction,
    profile: {
      ...auction.profile,
      search_location: {
        ...auction.profile?.search_location,
        encrypted_house_number: {
          ...mockEncryptedContent,
          decrypted: "12",
        },
      },
    },
    patient: {
      ...auction.patient,
      profile: {
        __typename: "PatientProfile",
        ...auction.patient.profile,
        birth_date: {
          ...mockEncryptedContent,
          decrypted: "12.12.1964",
        },
        first_name: {
          ...mockEncryptedContent,
          decrypted: "Jean",
        },
        last_name: {
          ...mockEncryptedContent,
          decrypted: "Bon",
        },
      },
    },
  };
}

function getEncryptedAuction(
  auction: Auction,
  withEncryption: boolean,
): Auction {
  return withEncryption ? addEncryptedFields(auction) : auction;
}

function getAssessmentProps(
  assessmentAuction: Auction,
  slug: string,
  withEncryption: boolean,
): AssessmentStepProps<any> {
  const encryptedAuction = getEncryptedAuction(
    assessmentAuction,
    withEncryption,
  );

  return {
    slug,
    fieldSectionHeader: [],
    account: { __typename: "Account", id: 1 },
    auction: encryptedAuction,
    withEncryption,
    translations: getTestTranslations(),
    isLoading: false,
    onSubmit: () => {},
    stepperTitles: [],
    activeStep: 2,
    formInputValue: encryptedAuction,
    careseeker: encryptedAuction.patient?.careseeker as Careseeker,
    breakingChange: { current: false },
    setBreakingChange: () => {},
  };
}

export const storyCases = (slug: string) => [
  {
    title: "No Encryption",
    props: getAssessmentProps(careAuction, slug, false),
  },
  {
    title: "Encryption",
    props: getAssessmentProps(careAuction, slug, true),
  },
];

export const getAuction = (
  solution: number,
  specializations: Array<Specialization> | null | undefined,
  country?: string,
  assessment_variant?: AssessmentVariantType,
): Auction => {
  let testAuction;
  if (CARE_SOLUTIONS.includes(solution)) {
    testAuction = careAuction;
  } else if (HOSPITAL_SOLUTIONS.includes(solution)) {
    testAuction = hospitalAuction;
  } else if (REHABILITATION_SOLUTIONS.includes(solution)) {
    testAuction = rehabAuction;
  }

  const newAuction: Auction = {
    __typename: "Auction",
    ...testAuction,
    assessment_variant,
    id: 1,
    search_type: testAuction?.search_type || SEARCH_TYPE_CARE,
    solutions: [solution],
    specializations: specializations || undefined,
    patient: {
      ...testAuction?.patient,
      id: 10,
    },
  };

  if (newAuction.patient.careseeker?.address)
    newAuction.patient.careseeker.address.country = country || "DE";
  return newAuction;
};
