import {
  ASSESSMENT_SLUG_MEDICAL,
  ASSESSMENT_SLUG_MENTAL,
  ASSESSMENT_SLUG_MOBILE_SHORT,
  ASSESSMENT_SLUG_MOBILITY,
  CARESEEKER_TYPE_EMERGENCY,
  CARESEEKER_TYPE_HOSPITAL,
  CORONA_NOT_TESTED,
  CORONA_TESTED_CURED,
  CORONA_TESTED_NEGATIVE,
  CORONA_TESTED_NOT_CURED,
  CORONA_TEST_PENDING,
  CORONA_UNKNOWN,
  INFECTION_STATUS,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
  SOLUTION_MOBILE_CARE,
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
} from "core/consts";
import { descriptiveWhitelist, isRehabSearch } from "core/model/auctions";
import { formatUnixDate } from "core/model/utils/dates";
import {
  Auction,
  BarthelAssessment,
  BarthelIndex as BarthelIndexType,
  CoronaTest,
  DependencyDiagnosis as DependencyDiagnosisType,
  EarlyRehabAssessment,
  GetOntologyType,
  HospitalStay as HospitalStayType,
  IcdCode,
  InfectionAndGerms as InfectionAndGermsObjectType,
  LimitedCapacity as LimitedCapacityType,
  MedicalDiagnosis as MedicalDiagnosisType,
  MentalHealth as MentalHealthType,
  Patient,
  PatientWhitelistDefinition,
  PatientWhitelistScope,
  PhysicalDisabilities as PhysicalDisabilitiesType,
  RehabIndex,
  RehabilitationPotential as RehabilitationPotentialType,
  SearchType,
  SingerAssessment,
  Specialization,
  Station,
  SupportedLanguage,
} from "core/types";
import {
  ACCENT_COLOR,
  ACCENT_LIGHT_COLOR,
  APP_BACKGROUND,
  ERROR_COLOR,
  ERROR_LIGHT_COLOR,
  MEDIUM_GREY,
  SUCCESS_COLOR,
  SUCCESS_LIGHT_COLOR,
} from "ds_legacy/materials/colors";
import {
  Divider,
  HorizontalLayout,
  VerticalLayout,
} from "ds_legacy/materials/layouts";
import { dp, margin, padding } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_WEIGHT_BOLD,
  Subheading,
  Title,
} from "ds_legacy/materials/typography";
import { useAuctionRequestContext } from "dsl/atoms/Contexts";
import {
  useCareproviderHandlerUrls,
  useCareseekerHandlerUrls,
} from "dsl/hooks/useNavigationHandlers";
import { usePatientInfoContext } from "dsl/organisms/Infos/PatientInformation";
import { AlertTriangleIcon } from "lucide-react";
import { getPredicamentValue } from "react-forms-state";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLocale, useTranslations } from "translations";
import Translations from "translations/types";
import { PatientInfoSlugContext } from "./index";
import { medicalCardWhitelist } from "./modelDefinition";
import {
  BodyWrapper,
  BooleanField,
  CareproviderInfoCard,
  Categories,
  Category,
  CategoryContainer,
  CategoryType,
  ClinicInfoCard,
  EmptiableCategory,
  EmptiableField,
  OntologyField,
  StringField,
  ValueGetter,
  activableInputCollectionIsNotEmpty,
  exists,
  isInfectionsAndGermsPredicamentNegative,
  isNumberAndNotZero,
  isWhitelisted,
} from "./shared";

const StyledLink = styled(Body)`
  &:hover {
    text-decoration: underline;
  }
  font-weight: bold;
  cursor: pointer;
  margin: 0;
`;

export const showInfection: PatientWhitelistScope[] = [
  {
    country: "FR",
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_REHABILITATION,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_HOSPITAL,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_TRANSPORT,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_CARE,
    solutions: [
      SOLUTION_MOBILE_CARE,
      SOLUTION_SHORT_TERM_STATIC_CARE,
      SOLUTION_STATIC_CARE,
    ],
  },
];

function DiagnosisDescription({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <StringField
      testId="diagnosis_description"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
      multiLine
      wrap={(content) => (
        <HorizontalLayout stretched width="100%">
          <Category
            fullWidth
            category="diagnosis_description"
            title={translations.patient.medicalDiagnosis.mainDiagnosis.label}
          >
            {content}
          </Category>
        </HorizontalLayout>
      )}
    />
  );
}

function SecondaryDiagnosisDescription({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <StringField
      testId="secondary_description"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
      multiLine
      wrap={(content) => (
        <HorizontalLayout stretched>
          <Category
            fullWidth
            category="secondary_description"
            title={translations.patient.medicalDiagnosis.secondaryDiagnosis}
          >
            {content}
          </Category>
        </HorizontalLayout>
      )}
    />
  );
}

function MorbidityStatus({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: boolean | null | undefined;
  translations: Translations;
  value: boolean | null | undefined;
  withDiff: boolean;
}) {
  return (
    <BooleanField
      label={translations.patient.medicalDiagnosis.multiMorbidPatient}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
      wrap={(content) => (
        <HorizontalLayout stretched>
          <CategoryContainer fullWidth>{content}</CategoryContainer>
        </HorizontalLayout>
      )}
    />
  );
}

function AdditionalSpecializations({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: Array<Specialization> | null | undefined;
  translations: Translations;
  value: Array<Specialization> | null | undefined;
  withDiff: boolean;
}) {
  if (!value?.length && !oldValue?.length) return null;

  return (
    <Category
      category="additional_specializations"
      title={translations.patient.specialisedAdvices}
    >
      <OntologyField
        getOntology={getOntology}
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        type="specializations"
      />
    </Category>
  );
}

function MedicalHistory({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  if (!value && !oldValue) return null;
  return (
    <Category
      category="medical_history"
      title={translations.patient.medicalHistory}
    >
      <StringField
        testId="medical_history"
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        multiLine
      />
    </Category>
  );
}

function AdditionalExaminationsNecessary({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  if (!value && !oldValue) return null;
  return (
    <Category
      category="additional_examinations_necessary"
      title={translations.patient.additionalExaminations}
    >
      <StringField
        testId="additional_examinations_necessary"
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        multiLine
      />
    </Category>
  );
}

const sumValues = (formValue: AnyObject) =>
  Object.values(formValue)
    .filter((v) => typeof v === "number")
    .reduce((currentValue, newValue) => currentValue + newValue, 0);

export const getIndexValues = (
  list: BarthelIndexType[] | RehabIndex[] | undefined,
  stringIndexValue: string | null | undefined,
  locale: SupportedLanguage = "de",
): {
  indexFormSummary: string | null;
  stringIndexValue: string | null | undefined;
} => {
  const defaultResponse = {
    indexFormSummary: null,
    stringIndexValue,
  };
  if (!list?.length) return defaultResponse;
  const latestIndex = list.clone().sort((a, b) => a.date - b.date)[0];
  if (!latestIndex.form || !latestIndex.date) return defaultResponse;

  const indexFormValues = Object.values(latestIndex.form);
  const indexDate = formatUnixDate(latestIndex.date, locale);
  const indexFormSummary = `${sumValues(indexFormValues)} (${indexDate})`;

  return {
    indexFormSummary,
    stringIndexValue,
  };
};

export function IndependenceScaleResult({
  goTo,
  linkLabel,
  testId,
  title,
  value,
}: {
  goTo?: string;
  linkLabel?: string;
  testId:
    | "singer-assessment-result"
    | "early-rehab-assessment-result"
    | "barthel-assessment-result";
  title: string;
  value?: SingerAssessment | BarthelAssessment | EarlyRehabAssessment;
}) {
  const translations = useTranslations();
  const locale = useLocale();

  if (typeof value?.score !== "number" || !value.date) return null;

  return (
    <VerticalLayout data-testid={testId} padding={padding(0, 0, 1, 2)}>
      <Body as="h3" style={{ fontWeight: FONT_WEIGHT_BOLD }} margin={margin(0)}>
        {title}
      </Body>
      <VerticalLayout as="dl" boxSizing="border-box" width="100">
        <HorizontalLayout gap={margin(0.25)} data-testid={`${testId}-score`}>
          <Body as="dt" margin={margin(0)}>
            {
              translations.patient.medicalDiagnosis.singerPatientProfile
                .scoreTitle
            }
          </Body>
          <Body as="dl" margin={margin(0)}>
            {value.score}
          </Body>
        </HorizontalLayout>
        <HorizontalLayout gap={margin(0.25)} data-testid={`${testId}-date`}>
          <Body as="dt" margin={margin(0)}>
            {
              translations.patient.medicalDiagnosis.singerPatientProfile
                .dateTitle
            }
          </Body>
          <Body as="dl" margin={margin(0)}>
            {formatUnixDate(value.date as number, locale)}
          </Body>
        </HorizontalLayout>
      </VerticalLayout>
      {value.form && goTo && linkLabel && (
        <StyledLink as={Link} to={goTo} primary>
          {linkLabel}
        </StyledLink>
      )}
    </VerticalLayout>
  );
}

function DependencyDiagnosis({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: DependencyDiagnosisType | null | undefined;
  translations: Translations;
  value: DependencyDiagnosisType | null | undefined;
  withDiff: boolean;
}) {
  const { patient } = useCareseekerHandlerUrls();
  const { goToBarthel, goToEarlyRehab, goToSinger } =
    useCareproviderHandlerUrls();
  const { isClinicApp } = usePatientInfoContext();
  const { auctionRequestId, careproviderId } = useAuctionRequestContext();

  if (withDiff ? !value && !oldValue : !value) return null;

  return (
    <Category
      category="dependency_diagnosis"
      title={translations.patient.autonomy}
    >
      <PatientInfoSlugContext.Consumer>
        {({
          auctionId,
          patientId,
        }: {
          auctionId: number;
          patientId: number;
        }) => (
          <>
            <IndependenceScaleResult
              goTo={
                isClinicApp
                  ? patient.goToBarthel({ auctionId, patientId })
                  : goToBarthel({
                      auctionRequestId: +(auctionRequestId || -1),
                      careproviderId: +(careproviderId || -1),
                    })
              }
              linkLabel={translations.patient.medicalDiagnosis.barthelLink}
              testId="barthel-assessment-result"
              title={
                translations.patient.medicalDiagnosis.barthelIndexPlaceholder
              }
              value={value?.barthel_assessment}
            />
            <IndependenceScaleResult
              goTo={
                isClinicApp
                  ? patient.goToSinger({ auctionId, patientId })
                  : goToSinger({
                      auctionRequestId: +(auctionRequestId || -1),
                      careproviderId: +(careproviderId || -1),
                    })
              }
              linkLabel={
                translations.patient.medicalDiagnosis.singerPatientProfile
                  .singerLink
              }
              testId="singer-assessment-result"
              title={
                translations.patient.medicalDiagnosis.singerIndexPlaceholder
              }
              value={value?.singer_assessment}
            />
            <IndependenceScaleResult
              goTo={
                isClinicApp
                  ? patient.goToEarlyRehab({ auctionId, patientId })
                  : goToEarlyRehab({
                      auctionRequestId: +(auctionRequestId || -1),
                      careproviderId: +(careproviderId || -1),
                    })
              }
              linkLabel={
                translations.patient.medicalDiagnosis.earlyRehabIndex
                  .earlyRehabLink
              }
              testId="early-rehab-assessment-result"
              title={
                translations.patient.medicalDiagnosis
                  .earlyRehabBarthelIndexPlaceholder
              }
              value={value?.early_rehab_assessment}
            />
            <EmptiableField
              title={translations.patient.needsHelpWalking}
              value={getPredicamentValue({
                translations,
                value: value?.requires_walking_aid,
              })}
              testId="requires_walking_aid"
              oldValue={getPredicamentValue({
                translations,
                value: oldValue?.requires_walking_aid,
              })}
              withDiff={withDiff}
              noEmptyValue
            />
            <EmptiableField
              title={translations.patient.needsHelpEating}
              value={getPredicamentValue({
                translations,
                value: value?.requires_support_with_feeding,
              })}
              testId="requires_support_with_feeding"
              oldValue={getPredicamentValue({
                translations,
                value: oldValue?.requires_support_with_feeding,
              })}
              withDiff={withDiff}
              noEmptyValue
            />
            <EmptiableField
              title={translations.patient.agitationOrientation}
              value={getPredicamentValue({
                translations,
                value: value?.agitated,
              })}
              testId="agitated"
              oldValue={getPredicamentValue({
                translations,
                value: oldValue?.agitated,
              })}
              withDiff={withDiff}
              noEmptyValue
            />
            <StringField
              testId="agitated_description"
              value={value?.agitated_description}
              oldValue={oldValue?.agitated_description}
              withDiff={withDiff}
            />
            <EmptiableField
              title={translations.patient.hasHelpAtHome}
              value={getPredicamentValue({
                translations,
                value: value?.has_help_at_home,
              })}
              testId="has_help_at_home"
              oldValue={getPredicamentValue({
                translations,
                value: oldValue?.has_help_at_home,
              })}
              withDiff={withDiff}
              noEmptyValue
            />
            <EmptiableField
              title={translations.patient.medicalDiagnosis.shortTerm}
              value={getPredicamentValue({
                translations,
                value: value?.returning_home_short_term,
              })}
              testId="returning_home_short_term"
              oldValue={getPredicamentValue({
                translations,
                value: oldValue?.returning_home_short_term,
              })}
              withDiff={withDiff}
              noEmptyValue
            />
          </>
        )}
      </PatientInfoSlugContext.Consumer>
    </Category>
  );
}

function Dysphagia({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.careNeeds.dysphagia.label}
      category="dysphagia"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
      testId="dysphagia"
    />
  );
}

function ChronicPains({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.medicalDiagnosis.chronicPains}
      category="chronic_pains"
      testId="chronic_pains"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function Allergies({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.medicalDiagnosis.allergies}
      category="allergies"
      testId="allergies"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function getAdditionalIcdCodesList(icd_codes: Array<IcdCode>) {
  return icd_codes.map(
    (codeItem) => `(${codeItem.code}) ${codeItem.description} `,
  );
}

type IcdValue = {
  additional_icd_codes: Array<IcdCode> | null | undefined;
  icd_code: IcdCode | null | undefined;
};

export function IcdCodesCommon({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  additional_icd_codes?: Array<IcdCode>;
  icd_code?: IcdCode;
  oldValue?: IcdValue | null | undefined;
  translations: Translations;
  value: IcdValue | null | undefined;
  withDiff?: boolean;
}) {
  return (
    <>
      <Subheading bold>
        {translations.patient.medicalDiagnosis.icdCodes}
      </Subheading>
      <StringField
        testId="icd_code_description"
        value={
          value?.icd_code?.code &&
          value.icd_code.description &&
          `(${value.icd_code.code}) ${value.icd_code.description}`
        }
        oldValue={
          oldValue?.icd_code?.code &&
          oldValue.icd_code.description &&
          `(${oldValue.icd_code.code}) ${oldValue.icd_code.description}`
        }
        withDiff={withDiff}
        prefix={
          translations.patient.medicalDiagnosis.icdCodeMainPatientInfoLabel
        }
      />
      <StringField
        testId="additional_icd_codes"
        value={
          value?.additional_icd_codes &&
          getAdditionalIcdCodesList(value.additional_icd_codes).join("\n")
        }
        oldValue={
          oldValue?.additional_icd_codes &&
          getAdditionalIcdCodesList(oldValue.additional_icd_codes).join("\n")
        }
        withDiff={withDiff}
        multiLine
        wrap={(content) => (
          <BodyWrapper column>
            {`${translations.patient.medicalDiagnosis.icdCodesAdditionalPatientInfoLabel}${translations.general.colon} `}
            <div style={{ marginLeft: dp(-16) }}>{content}</div>
          </BodyWrapper>
        )}
      />
    </>
  );
}

function IcdCodes({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: IcdValue | null | undefined;
  translations: Translations;
  value: IcdValue | null | undefined;
  withDiff: boolean;
}) {
  return (
    <CategoryContainer className="icd_codes">
      <IcdCodesCommon
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        translations={translations}
      />
    </CategoryContainer>
  );
}

type InfectionAndGermsType = {
  infection_and_germs?: InfectionAndGermsObjectType;
  infection_and_germs_state?: number;
};

export function InfectionAndGerms({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: InfectionAndGermsType | null | undefined;
  translations: Translations;
  value: InfectionAndGermsType;
  withDiff: boolean;
}) {
  const [negativeState, negativeStateMessage] =
    isInfectionsAndGermsPredicamentNegative(value, translations);
  const [oldStateNegativeState] = isInfectionsAndGermsPredicamentNegative(
    oldValue,
    translations,
  );
  const withNegativePredicament = withDiff
    ? negativeState && oldStateNegativeState
    : negativeState;
  return (
    <Category
      category="infection_and_germs"
      title={translations.patient.medicalDiagnosis.infections}
    >
      {withNegativePredicament ? (
        <Body>{negativeStateMessage}</Body>
      ) : (
        <>
          <BooleanField
            value={value?.infection_and_germs?.requires_isolation}
            oldValue={oldValue?.infection_and_germs?.requires_isolation}
            label={
              translations.patient.medicalDiagnosis.infectionsRequiresIsolation
            }
            withDiff={withDiff}
          />
          <BooleanField
            value={value?.infection_and_germs?.mrsa}
            oldValue={oldValue?.infection_and_germs?.mrsa}
            withDiff={withDiff}
            label={translations.patient.medicalDiagnosis.infectionsMRSA}
          />
          <BooleanField
            value={value?.infection_and_germs?.clostridien}
            oldValue={oldValue?.infection_and_germs?.clostridien}
            withDiff={withDiff}
            label={translations.patient.medicalDiagnosis.infectionsClostridien}
          />
          <BooleanField
            value={value?.infection_and_germs?.three_mrgn}
            oldValue={oldValue?.infection_and_germs?.three_mrgn}
            withDiff={withDiff}
            label={translations.patient.medicalDiagnosis.infectionsThreeMRGN}
          />
          <BooleanField
            value={value?.infection_and_germs?.four_mrgn}
            oldValue={oldValue?.infection_and_germs?.four_mrgn}
            withDiff={withDiff}
            label={translations.patient.medicalDiagnosis.infectionsFourMRGN}
          />
          <BooleanField
            value={value?.infection_and_germs?.vre}
            oldValue={oldValue?.infection_and_germs?.vre}
            withDiff={withDiff}
            label={translations.patient.medicalDiagnosis.infectionsVRE}
          />
          <EmptiableField
            prefix={translations.patient.medicalDiagnosis.infectionsOther.label}
            value={value?.infection_and_germs?.other}
            oldValue={oldValue?.infection_and_germs?.other}
            withDiff={withDiff}
            testId="infection_and_germs_other"
          />
        </>
      )}
      <EmptiableField
        value={value?.infection_and_germs?.remarks || ""}
        oldValue={oldValue?.infection_and_germs?.remarks}
        withDiff={withDiff}
        noEmptyValue={
          value?.infection_and_germs_state !== INFECTION_STATUS.UNKNOWN
        }
        testId="infection_and_germs_remarks"
      />
    </Category>
  );
}

export const coronaSwitch = (translations: Translations, value: CoronaTest) => {
  switch (value?.corona_state) {
    case CORONA_NOT_TESTED:
      return {
        background: ACCENT_LIGHT_COLOR,
        icon: ACCENT_COLOR,
        label: translations.coronaTesting.patientNotTested,
      };
    case CORONA_UNKNOWN:
      return {
        background: APP_BACKGROUND,
        icon: MEDIUM_GREY,
        label: translations.coronaTesting.unknown,
      };
  }
  switch (value?.corona?.test_result) {
    case CORONA_TESTED_CURED:
      return {
        background: SUCCESS_LIGHT_COLOR,
        icon: SUCCESS_COLOR,
        label: `${translations.coronaTesting.results}${translations.general.colon} ${translations.coronaTesting.positiveCured}`,
      };
    case CORONA_TESTED_NOT_CURED:
      return {
        background: ERROR_LIGHT_COLOR,
        icon: ERROR_COLOR,
        label: `${translations.coronaTesting.results}${translations.general.colon} ${translations.coronaTesting.positiveUncured}`,
      };
    case CORONA_TESTED_NEGATIVE:
      return {
        background: SUCCESS_LIGHT_COLOR,
        icon: SUCCESS_COLOR,
        label: translations.coronaTesting.negative,
      };
    case CORONA_TEST_PENDING:
      return {
        background: ACCENT_LIGHT_COLOR,
        icon: ACCENT_COLOR,
        label: `${translations.coronaTesting.results}${translations.general.colon} ${translations.coronaTesting.pending}`,
      };
    default:
      return { background: undefined, icon: ACCENT_COLOR, label: undefined };
  }
};

function getVaccinatedValue({
  translations,
  value,
}: {
  translations: Translations;
  value: CoronaTest;
}) {
  if (value?.corona?.vaccinated === "") {
    return translations.coronaTesting.hasBeenVaccinated;
  }
  if (typeof value?.corona?.vaccinated === "string") {
    return `${translations.coronaTesting.hasBeenVaccinated}\n ${value.corona.vaccinated}`;
  }
  return translations.coronaTesting.vaccinationUnknown;
}

export function CoronaVirusTesting({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: CoronaTest;
  translations: Translations;
  value: CoronaTest;
  withDiff: boolean;
}) {
  const { background, icon, label } = coronaSwitch(translations, value);
  const { label: oldLabel } = coronaSwitch(translations, oldValue);

  return (
    <CategoryContainer className="corona_testing">
      <div style={{ backgroundColor: background }}>
        <HorizontalLayout>
          <Subheading bold>
            {translations.coronaTesting.auctionLabel}
          </Subheading>
          <div style={{ padding: padding(1.2, 0, 0, 0) }}>
            <AlertTriangleIcon style={{ color: icon }} size={16} />
          </div>
        </HorizontalLayout>
        <VerticalLayout margin={margin(0, 0, 1, 0)}>
          <EmptiableField
            value={label}
            oldValue={oldLabel}
            withDiff={withDiff}
            testId="corona_testing"
          />
          <EmptiableField
            value={value?.corona?.description}
            oldValue={oldValue?.corona?.description}
            withDiff={withDiff}
            testId="corona_testing_description"
          />
        </VerticalLayout>
        <VerticalLayout margin={margin(1, 0, 1, 0)}>
          <EmptiableField
            value={getVaccinatedValue({ value, translations })}
            oldValue={getVaccinatedValue({ value: oldValue, translations })}
            withDiff={withDiff}
            testId="corona_testing_vaccination"
          />
        </VerticalLayout>
      </div>
    </CategoryContainer>
  );
}

function Addictions({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.medicalDiagnosis.addictions}
      category="addictions"
      testId="addictions"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function Vigilance({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: number | null | undefined;
  translations: Translations;
  value: number | null | undefined;
  withDiff: boolean;
}) {
  return (
    <OntologyField
      getOntology={getOntology}
      type="vigilance"
      label={translations.ontologies.vigilance.key}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function PatientOrientation({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: number | null | undefined;
  translations: Translations;
  value: number | null | undefined;
  withDiff: boolean;
}) {
  return (
    <OntologyField
      getOntology={getOntology}
      type="orientation"
      label={translations.ontologies.orientation.key}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function PatientMotivation({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: number | null | undefined;
  translations: Translations;
  value: number | null | undefined;
  withDiff: boolean;
}) {
  return (
    <OntologyField
      getOntology={getOntology}
      type="motivation"
      label={translations.ontologies.motivation.key}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function PatientCommunication({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: number | null | undefined;
  translations: Translations;
  value: number | null | undefined;
  withDiff: boolean;
}) {
  return (
    <OntologyField
      getOntology={getOntology}
      type="communication"
      label={translations.ontologies.communication.key}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function Dementia({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.dementia}
      category="dementia"
      testId="dementia"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function RunAway({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.closedAreaNeeded}
      category="run_away"
      testId="run_away"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function ProtectedAreaNeeded({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.mentalState.protectedAreaNeeded.label}
      category="protected_area_needed"
      testId="protected_area_needed"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function Nightwatch({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.mentalState.nightwatch.label}
      category="night_watch"
      testId="night_watch"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function FacilityCharacteristics({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: MentalHealthType | null | undefined;
  translations: Translations;
  value: MentalHealthType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category
      category="factility_characteristics"
      title={translations.patient.mentalState.facilityCharacteristics}
    >
      <BooleanField
        label={translations.patient.mentalState.closedStationRequired}
        value={value?.closed_station}
        oldValue={oldValue?.closed_station}
        withDiff={withDiff}
      />
      <BooleanField
        label={translations.patient.mentalState.fixationRequired}
        value={value?.fixation}
        oldValue={oldValue?.fixation}
        withDiff={withDiff}
      />
    </Category>
  );
}

function MentalIssues({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: MentalHealthType | null | undefined;
  translations: Translations;
  value: MentalHealthType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category
      category="mental_health"
      title={translations.patient.mentalState.mentalIssues}
    >
      <BooleanField
        value={value?.depression}
        oldValue={oldValue?.depression}
        label={translations.patient.mentalState.depression}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.anxiety}
        oldValue={oldValue?.anxiety}
        label={translations.patient.mentalState.anxiety}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.aggressivity}
        oldValue={oldValue?.aggressivity}
        label={translations.patient.mentalState.aggressivity}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.dangerous}
        oldValue={oldValue?.dangerous}
        label={translations.patient.mentalState.dangerous}
        withDiff={withDiff}
      />
      <StringField
        testId="mental_issues"
        prefix={translations.patient.mentalState.mentalIssuesOther}
        value={value?.mental_issues}
        oldValue={oldValue?.mental_issues}
        withDiff={withDiff}
      />
    </Category>
  );
}

function PatientMobility({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: PhysicalDisabilitiesType | null | undefined;
  translations: Translations;
  value: PhysicalDisabilitiesType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category category="mobility" title={translations.ontologies.mobility.key}>
      <OntologyField
        getOntology={getOntology}
        value={value?.mobility}
        oldValue={oldValue?.mobility}
        withDiff={withDiff}
        type="mobility"
      />
      <StringField
        testId="mobility_additional_information"
        value={value?.mobility_additional_information}
        oldValue={oldValue?.mobility_additional_information}
        withDiff={withDiff}
      />
    </Category>
  );
}

function VisualImpairment({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.mobility.visual_impairment.label}
      category="visual_impairment"
      testId="visual_impairment"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function AuditiveImpairment({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.mobility.auditive_impairment.label}
      category="auditive_impairment"
      testId="auditive_impairment"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

type AssistiveEquipmentType = Pick<
  PhysicalDisabilitiesType,
  | "assistive_equipment"
  | "existing_medical_supplies"
  | "prescribed_medical_supplies"
  | "required_medical_supplies"
>;

function AssistiveEquipment({
  notEmpty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  notEmpty: boolean;
  oldValue: AssistiveEquipmentType;
  translations: Translations;
  value: AssistiveEquipmentType;
  withDiff: boolean;
}) {
  const {
    assistive_equipment,
    existing_medical_supplies,
    prescribed_medical_supplies,
    required_medical_supplies,
  } = value;

  const newValue =
    !!existing_medical_supplies ||
    !!prescribed_medical_supplies ||
    !!required_medical_supplies;

  return newValue ? (
    <EmptiableCategory
      empty={notEmpty}
      title={translations.patient.mobility.assistiveEquipment}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={translations.patient.medicalSupplies.existingMedicalSupplies}
        value={existing_medical_supplies}
        oldValue={oldValue?.existing_medical_supplies}
        testId="existing_medical_supplies"
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalSupplies.prescribedMedicalSupplies}
        value={prescribed_medical_supplies}
        oldValue={oldValue?.prescribed_medical_supplies}
        testId="prescribed_medical_supplies"
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalSupplies.neededMedicalSupplies}
        value={required_medical_supplies}
        oldValue={oldValue?.required_medical_supplies}
        testId="required_medical_supplies"
        withDiff={withDiff}
      />
    </EmptiableCategory>
  ) : (
    <EmptiableField
      title={translations.patient.mobility.assistiveEquipment}
      value={assistive_equipment}
      oldValue={oldValue?.assistive_equipment}
      testId="assistive_equipment"
      category="assistive_equipment"
      withDiff={withDiff}
    />
  );
}

function Orthosis({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.mobility.orthese.label}
      category="orthosis"
      testId="orthosis"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function Prothesis({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.mobility.prothese.label}
      category="prothese"
      testId="prothese"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}
type HospitalStayProps = {
  hospital_stay?: HospitalStayType;
  search_type?: SearchType;
  station_full?: Station;
};

function HospitalStay({
  forClinic,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  forClinic: boolean | null | undefined;
  oldValue?: HospitalStayProps | null;
  translations: Translations;
  value?: HospitalStayProps | null;
  withDiff: boolean;
}) {
  const locale = useLocale();

  const isReha = isRehabSearch(value?.search_type);

  const hasCommonFields =
    !!value?.hospital_stay?.doctor_in_charge_in_hospital_phone ||
    !!value?.station_full?.name;

  const hasRehaFields =
    isReha &&
    (!!value?.hospital_stay?.operation_date ||
      !!value?.hospital_stay?.operation_description ||
      !!value?.hospital_stay?.geriatric_early_stage ||
      !!value?.hospital_stay?.doctor_in_charge_in_hospital);

  const notEmpty = hasCommonFields || hasRehaFields;

  const showHospitalStay = forClinic ? isReha || notEmpty : isReha && notEmpty;

  if (!showHospitalStay) return null;

  return (
    <EmptiableCategory
      title={translations.patient.medicalDiagnosis.hospitalStay}
      empty={!notEmpty}
    >
      <EmptiableField
        title={translations.patient.medicalDiagnosis.doctorInChargeInHospital}
        value={value?.hospital_stay?.doctor_in_charge_in_hospital}
        oldValue={oldValue?.hospital_stay?.doctor_in_charge_in_hospital}
        withDiff={withDiff}
        noEmptyValue
        testId="doctor_in_charge_in_hospital"
      />
      <EmptiableField
        title={translations.patient.station}
        value={value?.station_full?.name}
        oldValue={oldValue?.station_full?.name}
        withDiff={withDiff}
        testId="station_full_name"
      />
      <EmptiableField
        noEmptyValue
        title={translations.patient.stationPhoneNumber}
        value={value?.hospital_stay?.doctor_in_charge_in_hospital_phone}
        oldValue={oldValue?.hospital_stay?.doctor_in_charge_in_hospital_phone}
        withDiff={withDiff}
        testId="doctor_in_charge_in_hospital_phone"
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.operationDate}
        value={
          (value?.hospital_stay?.operation_date &&
            formatUnixDate(value?.hospital_stay?.operation_date, locale)) ||
          null
        }
        testId="operation_date"
        oldValue={
          (oldValue?.hospital_stay?.operation_date &&
            formatUnixDate(oldValue?.hospital_stay?.operation_date, locale)) ||
          null
        }
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.operationDescription}
        value={value?.hospital_stay?.operation_description}
        oldValue={oldValue?.hospital_stay?.operation_description}
        withDiff={withDiff}
        noEmptyValue
        testId="operation_description"
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.conductedEarlyRehab}
        value={value?.hospital_stay?.geriatric_early_stage}
        oldValue={oldValue?.hospital_stay?.geriatric_early_stage}
        withDiff={withDiff}
        testId="geriatric_early_stage"
      />
    </EmptiableCategory>
  );
}

function RehabilitationPotential({
  getOntology,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  oldValue: RehabilitationPotentialType | null | undefined;
  translations: Translations;
  value: RehabilitationPotentialType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <Category
      category="rehabilitation_potential"
      title={translations.patient.medicalDiagnosis.rehabilitationPotential}
    >
      <OntologyField
        getOntology={getOntology}
        value={value?.evaluation}
        oldValue={oldValue?.evaluation}
        withDiff={withDiff}
        type="rehabilitationPotential"
      />
      <StringField
        testId="evaluation_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </Category>
  );
}

function RehabilitationGoals({
  oldValue,
  translations,
  value,
  withDiff,
}: {
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableField
      title={translations.patient.medicalDiagnosis.rehabilitationGoals}
      category="rehabilitation_goals"
      testId="rehabilitation_goals"
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function LimitedCapacity({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: LimitedCapacityType | null | undefined;
  translations: Translations;
  value: LimitedCapacityType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.medicalDiagnosis.limitations}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={
          translations.patient.medicalDiagnosis.limitedPsychologicalCapacity
        }
        value={value?.limited_psychological_capacity}
        oldValue={oldValue?.limited_psychological_capacity}
        testId="limited_psychological_capacity"
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.limitedCognitiveCapacity}
        value={value?.limited_cognitive_capacity}
        oldValue={oldValue?.limited_cognitive_capacity}
        testId="limited_cognitive_capacity"
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.limitedSocialBehaviour}
        value={value?.limited_social_behaviour}
        oldValue={oldValue?.limited_social_behaviour}
        testId="limited_social_behaviour"
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.limitedPsychicalCapacity}
        value={value?.limited_physical_capacity}
        oldValue={oldValue?.limited_physical_capacity}
        testId="limited_physical_capacity"
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.medicalDiagnosis.limitedCapacityOther}
        value={value?.other}
        oldValue={oldValue?.other}
        testId="limited_other"
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function filterCategories(
  categoryKeysToFilter: Array<string>,
  categories: Array<CategoryType>,
) {
  return categories.filter((category) =>
    categoryKeysToFilter.includes(category.key),
  );
}

const medicalDiagnosisFields = [
  "main_diagnosis",
  "secondary_diagnosis",
  "medical_history",
  "additional_examinations_necessary",
  "multimorbidity",
  "icd_codes",
  "dependencyDiagnosis",
  "dysphagia",
  "chronic_pains",
  "allergies",
  "infection_and_germs",
  "addictions",
  "hospital_stay",
  "corona_testing",
  "rehabilitation_potential",
  "limited_capacity",
  "rehabilitation_goals",
  "additional_specializations",
];

const mentalStateFields = [
  "vigilance",
  "patient_orientation",
  "patient_motivation",
  "patient_communication",
  "dementia",
  "run_away",
  "protected_area_needed",
  "night_watch",
  "facility_characteristics",
  "mental_issues",
];

const physicalStateFields = [
  "patient_mobility",
  "visual_impairment",
  "auditive_impairment",
  "assistive_equipment",
  "orthosis",
  "prothesis",
];

export function dependencyDiagnosisExists(diagnosis: Patient["diagnosis"]) {
  if (!diagnosis?.dependency_diagnosis) {
    return false;
  }

  const {
    agitated,
    barthel_assessment,
    dependency_level,
    early_rehab_assessment,
    has_help_at_home,
    requires_support_with_feeding,
    requires_walking_aid,
    returning_home_short_term,
    singer_assessment,
  } = diagnosis.dependency_diagnosis;

  const hasValidDependencyLevel = isNumberAndNotZero(dependency_level);

  const hasBarthel = !!(
    barthel_assessment?.date && typeof barthel_assessment.score === "number"
  );

  const hasSinger = !!(
    singer_assessment?.date && typeof singer_assessment.score === "number"
  );

  const hasEarlyRehab = !!(
    early_rehab_assessment?.date &&
    typeof early_rehab_assessment.score === "number"
  );

  const requiresWalkingAid = isNumberAndNotZero(requires_walking_aid);
  const requiresSupportWithFeeding = isNumberAndNotZero(
    requires_support_with_feeding,
  );
  const isAgitated = isNumberAndNotZero(agitated);
  const hasHelpAtHome = isNumberAndNotZero(has_help_at_home);
  const returningHomeShortTerm = isNumberAndNotZero(returning_home_short_term);

  return (
    hasValidDependencyLevel ||
    hasBarthel ||
    hasSinger ||
    hasEarlyRehab ||
    requiresWalkingAid ||
    requiresSupportWithFeeding ||
    isAgitated ||
    hasHelpAtHome ||
    returningHomeShortTerm
  );
}

export const medicalFilters: {
  [key: string]: { exists: any; notEmpty?: any; valueGetter: ValueGetter };
} = {
  main_diagnosis: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.main_diagnosis,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.main_diagnosis != null,
  },
  secondary_diagnosis: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.secondary_diagnosis,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.secondary_diagnosis != null,
  },
  multimorbidity: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.multimorbidity,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.multimorbidity != null,
  },
  additional_specializations: {
    valueGetter: (auction) => auction?.additional_specializations,
    exists: (auction: Auction | null | undefined) =>
      auction?.additional_specializations !== null,
  },
  medical_history: {
    valueGetter: (auction) => auction?.patient.diagnosis?.medical_history,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.medical_history != null,
  },
  additional_examinations_necessary: {
    valueGetter: (auction) =>
      auction?.patient.diagnosis?.additional_examinations_necessary,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.additional_examinations_necessary != null,
  },
  icd_codes: {
    valueGetter: (auction) => ({
      icd_code: auction?.patient?.diagnosis?.icd_code,
      additional_icd_codes: auction?.patient?.diagnosis?.additional_icd_codes,
    }),
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis && (diagnosis.icd_code || diagnosis.additional_icd_codes),
  },
  infection_and_germs: {
    valueGetter: (auction) => ({
      infection_and_germs_state:
        auction?.patient?.diagnosis?.infection_and_germs_state,
      infection_and_germs: {
        description:
          auction?.patient?.diagnosis?.infection_and_germs?.description,
        requires_isolation:
          auction?.patient?.diagnosis?.infection_and_germs
            ?.requires_isolation || false,
        remarks: auction?.patient?.diagnosis?.infection_and_germs?.remarks,
        mrsa: auction?.patient?.diagnosis?.infection_and_germs?.mrsa || false,
        clostridien:
          auction?.patient?.diagnosis?.infection_and_germs?.clostridien ||
          false,
        three_mrgn:
          auction?.patient?.diagnosis?.infection_and_germs?.three_mrgn || false,
        four_mrgn:
          auction?.patient?.diagnosis?.infection_and_germs?.four_mrgn || false,
        vre: auction?.patient?.diagnosis?.infection_and_germs?.vre || false,
        other: auction?.patient?.diagnosis?.infection_and_germs?.other,
      },
    }),
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.infection_and_germs_state != null,
  },
  dependencyDiagnosis: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.dependency_diagnosis,
    exists: dependencyDiagnosisExists,
  },
  dysphagia: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.dysphagia,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.dysphagia != null,
  },
  chronic_pains: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.chronic_pains,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.chronic_pains != null,
  },
  allergies: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.allergies,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.allergies != null,
  },
  addictions: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.addictions,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.addictions != null,
  },
  vigilance: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.vigilance,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.mental_health?.vigilance != null &&
      diagnosis.mental_health.vigilance > 0,
  },
  patient_orientation: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.orientation,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      (diagnosis?.mental_health?.orientation &&
        diagnosis.mental_health.orientation != 0) === true,
  },
  patient_motivation: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.motivation,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      (diagnosis?.mental_health?.motivation &&
        diagnosis.mental_health.motivation != 0) === true,
  },
  patient_communication: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.communication,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      (diagnosis?.mental_health?.communication &&
        diagnosis.mental_health.communication != 0) === true,
  },
  dementia: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.dementia_description,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.mental_health?.dementia_description != null,
  },
  run_away: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.run_away_description,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.mental_health?.run_away_description != null,
  },
  protected_area_needed: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.protected_area_needed,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.mental_health?.protected_area_needed != null,
  },
  night_watch: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.mental_health?.night_watch_description,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.mental_health?.night_watch_description != null,
  },
  facility_characteristics: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.mental_health,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.mental_health &&
      (diagnosis.mental_health.closed_station == true ||
        diagnosis.mental_health.fixation == true),
  },
  mental_issues: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.mental_health,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.mental_health &&
      (diagnosis.mental_health.mental_issues ||
        diagnosis.mental_health.depression == true ||
        diagnosis.mental_health.anxiety == true ||
        diagnosis.mental_health.aggressivity == true ||
        diagnosis.mental_health.dangerous == true) &&
      true,
  },
  patient_mobility: {
    valueGetter: (auction) => ({
      mobility: auction?.patient?.diagnosis?.physical_disabilities?.mobility,
      mobility_additional_information:
        auction?.patient?.diagnosis?.physical_disabilities
          ?.mobility_additional_information,
    }),
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.physical_disabilities &&
      ((diagnosis.physical_disabilities.mobility &&
        diagnosis.physical_disabilities.mobility > 0) ||
        diagnosis.physical_disabilities.mobility_additional_information !=
          null),
  },
  visual_impairment: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.physical_disabilities?.visual_impairment,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.physical_disabilities?.visual_impairment != null,
  },
  auditive_impairment: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.physical_disabilities?.auditive_impairment,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.physical_disabilities?.auditive_impairment != null,
  },
  assistive_equipment: {
    valueGetter: (auction) => ({
      assistive_equipment:
        auction?.patient?.diagnosis?.physical_disabilities?.assistive_equipment,
      prescribed_medical_supplies:
        auction?.patient?.diagnosis?.physical_disabilities
          ?.prescribed_medical_supplies,
      existing_medical_supplies:
        auction?.patient?.diagnosis?.physical_disabilities
          ?.existing_medical_supplies,
      required_medical_supplies:
        auction?.patient?.diagnosis?.physical_disabilities
          ?.required_medical_supplies,
    }),
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.physical_disabilities?.assistive_equipment != null ||
      diagnosis?.physical_disabilities?.prescribed_medical_supplies != null ||
      diagnosis?.physical_disabilities?.existing_medical_supplies != null ||
      diagnosis?.physical_disabilities?.required_medical_supplies != null,
    notEmpty: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.physical_disabilities?.prescribed_medical_supplies != null ||
      diagnosis?.physical_disabilities?.existing_medical_supplies != null ||
      diagnosis?.physical_disabilities?.required_medical_supplies != null,
  },
  orthosis: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.physical_disabilities?.orthese,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.physical_disabilities?.orthese != null,
  },
  prothesis: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.physical_disabilities?.prothese,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.physical_disabilities?.prothese != null,
  },
  hospital_stay: {
    valueGetter: (auction: Auction | null | undefined) => ({
      station_full: auction?.patient?.station_full,
      hospital_stay: auction?.patient?.diagnosis?.hospital_stay,
      search_type: auction?.search_type,
    }),
    exists: () => true,
  },
  rehabilitation_potential: {
    valueGetter: (auction) =>
      auction?.patient?.diagnosis?.rehabilitation_potential,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.rehabilitation_potential != null &&
      (diagnosis.rehabilitation_potential.description != null ||
        diagnosis.rehabilitation_potential.evaluation != 0),
  },
  limited_capacity: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.limited_capacity,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      !!(
        diagnosis?.limited_capacity != null &&
        Object.keys(diagnosis.limited_capacity).length &&
        Object.keys(diagnosis.limited_capacity).filter((prop) => prop !== null)
          .length
      ),
    notEmpty: (diagnosis: MedicalDiagnosisType | null | undefined) => {
      const { limited_capacity } = diagnosis || ({} as ToType);
      return (
        limited_capacity && activableInputCollectionIsNotEmpty(limited_capacity)
      );
    },
  },
  rehabilitation_goals: {
    valueGetter: (auction) => auction?.patient?.diagnosis?.rehabilitation_goals,
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis &&
      typeof diagnosis.rehabilitation_goals === "string" &&
      diagnosis.rehabilitation_goals.trim() !== "",
  },
  corona_testing: {
    valueGetter: (auction) => ({
      corona: auction?.patient?.diagnosis?.corona,
      corona_state: auction?.patient?.diagnosis?.corona_state,
    }),
    exists: (diagnosis: MedicalDiagnosisType | null | undefined) =>
      diagnosis?.corona_state ||
      diagnosis?.corona?.test_result ||
      diagnosis?.corona?.description,
  },
};

function getWhitelist({ formInputValue }: { formInputValue: Auction }) {
  return {
    main_diagnosis: true,
    secondary_diagnosis: true,
    multimorbidity: true,
    medical_history: true,
    additional_examinations_necessary: true,
    icd_codes: true,
    additional_specializations: true,
    infection_and_germs: descriptiveWhitelist(showInfection)({
      formInputValue,
    }),
    dependencyDiagnosis: true,
    dysphagia: true,
    chronic_pains: true,
    allergies: true,
    addictions: true,
    vigilance: true,
    patient_orientation: true,
    patient_motivation: true,
    patient_communication: true,
    dementia: true,
    run_away: true,
    night_watch: true,
    facility_characteristics: true,
    mental_issues: true,
    patient_mobility: true,
    visual_impairment: true,
    auditive_impairment: true,
    assistive_equipment: true,
    orthosis: true,
    prothesis: true,
    hospital_stay: true,
    corona_testing: true,
    rehabilitation_potential: true,
    limited_capacity: true,
    rehabilitation_goals: true,
    protected_area_needed: true,
  };
}

const componentMapping = [
  {
    Component: DiagnosisDescription,
    key: "main_diagnosis",
    fullWidth: true,
  },
  {
    Component: SecondaryDiagnosisDescription,
    key: "secondary_diagnosis",
    fullWidth: true,
  },
  {
    Component: MorbidityStatus,
    key: "multimorbidity",
    fullWidth: true,
  },
  {
    Component: AdditionalSpecializations,
    key: "additional_specializations",
  },
  {
    Component: AdditionalExaminationsNecessary,
    key: "additional_examinations_necessary",
  },
  {
    Component: MedicalHistory,
    key: "medical_history",
  },
  {
    Component: IcdCodes,
    key: "icd_codes",
  },
  {
    Component: InfectionAndGerms,
    key: "infection_and_germs",
  },
  {
    Component: CoronaVirusTesting,
    key: "corona_testing",
  },
  {
    Component: DependencyDiagnosis,
    key: "dependencyDiagnosis",
  },
  {
    Component: Dysphagia,
    key: "dysphagia",
  },
  {
    Component: ChronicPains,
    key: "chronic_pains",
  },
  {
    Component: Allergies,
    key: "allergies",
  },
  {
    Component: Addictions,
    key: "addictions",
  },
  {
    Component: Vigilance,
    key: "vigilance",
  },
  {
    Component: PatientOrientation,
    key: "patient_orientation",
  },
  {
    Component: PatientMotivation,
    key: "patient_motivation",
  },
  {
    Component: PatientCommunication,
    key: "patient_communication",
  },
  {
    Component: Dementia,
    key: "dementia",
  },
  {
    Component: RunAway,
    key: "run_away",
  },
  {
    Component: ProtectedAreaNeeded,
    key: "protected_area_needed",
  },
  {
    Component: Nightwatch,
    key: "night_watch",
  },
  {
    Component: FacilityCharacteristics,
    key: "facility_characteristics",
  },
  {
    Component: MentalIssues,
    key: "mental_issues",
  },
  {
    Component: PatientMobility,
    key: "patient_mobility",
  },
  {
    Component: VisualImpairment,
    key: "visual_impairment",
  },
  {
    Component: AuditiveImpairment,
    key: "auditive_impairment",
  },
  {
    Component: AssistiveEquipment,
    key: "assistive_equipment",
  },
  {
    Component: Orthosis,
    key: "orthosis",
  },
  {
    Component: Prothesis,
    key: "prothesis",
  },
  {
    Component: HospitalStay,
    key: "hospital_stay",
  },
  {
    Component: RehabilitationPotential,
    key: "rehabilitation_potential",
  },
  {
    Component: LimitedCapacity,
    key: "limited_capacity",
  },
  {
    Component: RehabilitationGoals,
    key: "rehabilitation_goals",
  },
];

const getCategories = ({
  auction,
  oldAuction,
}: {
  auction: Auction;
  oldAuction?: Auction;
}) => {
  const whitelist = getWhitelist({
    formInputValue: auction,
  });
  const oldWhitelist = oldAuction
    ? getWhitelist({
        formInputValue: oldAuction,
      })
    : {};

  return componentMapping
    .filter((c) => {
      const key = c.key as keyof typeof oldWhitelist;
      const display = whitelist[key];
      if (display == null) {
        console.error("Missing whitelist for component", key);
        return true;
      }

      if (display === true) return true;

      return oldWhitelist[key] === true;
    })
    .map((c) => ({
      Component: c.Component,
      key: c.key,
      notEmpty:
        medicalFilters[c.key].notEmpty &&
        ((oldAuction != null &&
          medicalFilters[c.key].notEmpty(oldAuction?.patient.diagnosis)) ||
          medicalFilters[c.key].notEmpty(auction.patient.diagnosis)),
      exists:
        (oldAuction != null &&
          medicalFilters[c.key].exists(oldAuction?.patient.diagnosis)) ||
        medicalFilters[c.key].exists(auction.patient.diagnosis),
      valueGetter: medicalFilters[c.key].valueGetter,
      fullWidth: c.fullWidth || false,
    }));
};

const showMedicalCard = (auction: Auction) => {
  if (auction.assessment_variant === ASSESSMENT_SLUG_MOBILE_SHORT) return false;
  if (
    (
      [SEARCH_TYPE_HOME_CARE, SEARCH_TYPE_MEDICAL_SUPPLIES] as SearchType[]
    ).includes(auction.search_type)
  )
    return false;
  return true;
};

export default function MedicalCard({
  auction,
  forClinic,
  getOntology,
  oldAuction,
}: {
  auction: Auction;
  forClinic?: boolean;
  getOntology: GetOntologyType;
  oldAuction?: Auction;
}) {
  const withDiff = oldAuction != null;
  if (
    withDiff
      ? !auction.patient.diagnosis && !oldAuction?.patient.diagnosis
      : !auction.patient.diagnosis
  )
    return null;

  if (
    !isWhitelisted(medicalCardWhitelist, auction, oldAuction) ||
    !showMedicalCard(auction)
  )
    return null;

  return forClinic ? (
    <ClinicMedicalCard
      getOntology={getOntology}
      auction={auction}
      oldAuction={oldAuction}
    />
  ) : (
    <CareproviderMedicalCards
      getOntology={getOntology}
      auction={auction}
      oldAuction={oldAuction}
    />
  );
}

function ClinicMedicalCard({
  auction,
  getOntology,
  oldAuction,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
  oldAuction?: Auction;
}) {
  const categories = getCategories({
    auction,
    oldAuction,
  });

  const translations = useTranslations();

  const clinicTopCategories = filterCategories(
    medicalDiagnosisFields,
    categories,
  );

  const mentalStateCategories = filterCategories(mentalStateFields, categories);

  const physicalStateCategories = filterCategories(
    physicalStateFields,
    categories,
  );

  return (
    <ClinicInfoCard title={translations.patient.medicalDiagnosis.stepperTitle}>
      <PatientInfoSlugContext.Provider
        value={{
          patientId: auction.patient.id,
          auctionId: auction.id,
          assessmentSlug: ASSESSMENT_SLUG_MEDICAL,
        }}
      >
        <Categories
          getOntology={getOntology}
          auction={auction}
          oldAuction={oldAuction}
          translations={translations}
          categories={clinicTopCategories}
          forClinic
        />
      </PatientInfoSlugContext.Provider>
      {exists(mentalStateCategories) && (
        <>
          <Divider margin={margin(2, 0, 0, 2)} />
          <Title>{translations.patient.mentalHealth}</Title>
          <PatientInfoSlugContext.Provider
            value={{
              patientId: auction.patient.id,
              auctionId: auction.id,
              assessmentSlug: ASSESSMENT_SLUG_MENTAL,
            }}
          >
            <Categories
              getOntology={getOntology}
              auction={auction}
              oldAuction={oldAuction}
              translations={translations}
              categories={mentalStateCategories}
            />
          </PatientInfoSlugContext.Provider>
        </>
      )}
      {exists(physicalStateCategories) && (
        <>
          <Divider margin={margin(2, 0, 0, 2)} />
          <Title>{translations.patient.physicalHealth}</Title>
          <PatientInfoSlugContext.Provider
            value={{
              patientId: auction.patient.id,
              auctionId: auction.id,
              assessmentSlug: ASSESSMENT_SLUG_MOBILITY,
            }}
          >
            <Categories
              getOntology={getOntology}
              auction={auction}
              oldAuction={oldAuction}
              translations={translations}
              categories={physicalStateCategories}
            />
          </PatientInfoSlugContext.Provider>
        </>
      )}
    </ClinicInfoCard>
  );
}

const medicalDiagnosisWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    sender_type: CARESEEKER_TYPE_HOSPITAL,
  },
  {
    country: "FR",
    sender_type: CARESEEKER_TYPE_EMERGENCY,
  },
];

const CareproviderMedicalCards = ({
  auction,
  getOntology,
  oldAuction,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
  oldAuction?: Auction;
}) => {
  const translations = useTranslations();

  const categories = getCategories({
    auction,
    oldAuction,
  });

  const medicalDiagnosisCategories = filterCategories(
    medicalDiagnosisFields,
    categories,
  );

  const mentalStateCategories = filterCategories(mentalStateFields, categories);
  const physicalStateCategories = filterCategories(
    physicalStateFields,
    categories,
  );

  return (
    <>
      {isWhitelisted(medicalDiagnosisWhitelist, auction, oldAuction) && (
        <CareproviderInfoCard
          title={translations.patient.medicalDiagnosis.stepperTitle}
        >
          <Categories
            getOntology={getOntology}
            auction={auction}
            oldAuction={oldAuction}
            translations={translations}
            categories={medicalDiagnosisCategories}
          />
        </CareproviderInfoCard>
      )}
      {exists(mentalStateCategories) && (
        <CareproviderInfoCard title={translations.patient.mentalHealth}>
          <Categories
            getOntology={getOntology}
            auction={auction}
            oldAuction={oldAuction}
            translations={translations}
            categories={mentalStateCategories}
          />
        </CareproviderInfoCard>
      )}
      {exists(physicalStateCategories) && (
        <CareproviderInfoCard title={translations.patient.physicalHealth}>
          <Categories
            getOntology={getOntology}
            auction={auction}
            oldAuction={oldAuction}
            translations={translations}
            categories={physicalStateCategories}
          />
        </CareproviderInfoCard>
      )}
    </>
  );
};
